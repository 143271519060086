import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-final-modal/style.css'
import { createVfm } from 'vue-final-modal'
import eventBus from './eventBus'; // Importar el eventBus
loadFonts()
const vfm = createVfm()


fetch('/config.json')
  .then(response => response.json())
  .then(config => {
    const app = createApp(App)
    app.config.globalProperties.$apiUrl = config.API_URL
    app
      .use(VueAxios, axios)
      .use(router)
      .use(store)
      .use(vuetify)
      .use(vfm)
      .mount('#app')
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registrado con éxito', registration);

          navigator.serviceWorker.addEventListener('message', event => {
            const { title, message } = event.data;
            console.log(event.data)
            eventBus.showToast = { title, message };
          });
        })
        .catch(error => {
          console.error('Error al registrar el Service Worker', error);
        });
    }
  })
  .catch(error => {
    console.error("Error loading config.json", error);
  });
