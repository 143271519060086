<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="!$route.path.includes('/frios') && !$route.path.includes('/interno') && !$route.path.includes('/cocina') && !$route.path.includes('/pizza')"
      v-model="drawer" mobile-breakpoint="lgAndUp" app dark>
      <v-list lines="one">
        <v-list-group color="#ff0000">
          <v-list-item>
            <template v-slot:prepend>
              <img src="/assets/img/osolemio_logo.png" width="125" />
            </template>
          </v-list-item>
          <v-list-item v-for="(item, i) in itemsPro" :key="i" :to="item.route" dark>
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
          <v-list-item @click="subscribeUserToPush">
            <template v-slot:prepend>
              <v-icon>mdi-phone</v-icon>
            </template>
            <v-list-item-title v-if="suscribed == false"> Activar lector de llamadas</v-list-item-title>
            <v-list-item-title v-else> Activado lector de llamadas</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi-exit-to-app"></v-icon>
            </template>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="font-size:30pt">{{ formattedTime }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-if="$route.path.includes('/interno')" v-model="drawer" mobile-breakpoint="lgAndUp" app dark>
      <v-list lines="one">
        <v-list-group color="#ff0000">
          <v-list-item>
            <template v-slot:prepend>
              <img src="/assets/img/osolemio_logo.png" width="125" />
            </template>
          </v-list-item>
          <v-list-item v-for="(item, i) in itemsBasic" :key="i" :to="item.route" dark>
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-title style="font-size:30pt">{{ formattedTime }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-snackbar v-model="visible" :timeout="5000" location="top-right" closeable color="#f19015"  
      @click="redirectToUrl"  >
        {{ message }}
      </v-snackbar>
      <router-view :view="selected" />
    </v-main>
    <v-footer app       v-if="!$route.path.includes('/frios') && !$route.path.includes('/interno') && !$route.path.includes('/cocina') && !$route.path.includes('/pizza')"
    >
      <span>Omio group&copy; 2024 V 1.1b - Powered by
        <a href="https://grupowapps.es" rel="noopener" target="_blank"
          style="color:#fff;text-decoration: none;font-weight: bold">
          GrupoWapps
        </a>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import eventBus from './eventBus'; // Importar el eventBus
export default {
  props: {
    source: String,
  },

  data: () => ({
    visible: false,
    message: '',
    phone: null,
    suscribed: false,
    drawer: null,
    logged: false,
    isOpen: true,
    selected: 1,
    formattedTime: '--:--',
    itemsPro: [
      { icon: 'mdi-shopping', text: 'Pedidos Recog', route: '/' },
      { icon: 'mdi-silverware-fork-knife', text: 'Pedidos Mesas', route: '/sala' },
      { icon: 'mdi-basket-plus-outline', text: 'Nuevo Recoger', route: '/nuevopedido' },
      { icon: 'mdi-table-furniture', text: 'Nueva mesa', route: '/nuevopedidosala' },
      { icon: 'mdi-fast-forward', text: 'Pedido rápido', route: '/pedidorapido' },
      { icon: 'mdi-calendar-clock', text: 'Histórico', route: '/historial' },
      { icon: 'mdi-clock-fast', text: 'Disponibilidad', route: '/disponibilidad' },
      { icon: 'mdi-table', text: 'Zonas', route: '/zonas' },
      { icon: 'mdi-file-document-outline', text: 'Carta', route: '/carta' },
    ],
    itemsBasic: [
      { icon: 'mdi-shopping', text: 'Pedidos', route: '/interno/activos' },
      { icon: 'mdi-calendar-clock', text: 'Histórico', route: '/interno/historial' },
    ],
  }),
  created() {
    this.$vuetify.theme.dark = true;
    this.formattedTime = this.formatTime(new Date())
    setInterval(() => {
      this.formattedTime = this.formatTime(new Date());
    }, 60000);
  },
  mounted(){
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const data = event.data;
        console.log('Mensaje recibido del Service Worker:', data);

        // Actualizar el eventBus con los datos recibidos
        eventBus.showToast = {
          title: data.title,
          message: data.message,
        };
      });
    }
  },
  watch: {
    // Observa cambios en el eventBus
    showToast: {
      handler(newValue) {
        if (newValue) {
          this.phone = newValue.message
          this.message = `${newValue.title}: ${newValue.message}`;
          this.visible = true;

          // Resetea el evento para evitar mostrar el mismo mensaje repetidamente
          eventBus.showToast = null;
        }
      },
      immediate: true, // Llama inmediatamente al handler
    },
  },
  computed: {
    // Computed property para acceder al eventBus
    showToast() {
      return eventBus.showToast;
    }
  },
  methods: {
    redirectToUrl(){
      this.$router.push('/nuevopedido?phone='+this.phone)
    },
    async subscribeUserToPush() {
      const response = await fetch(`https://omioback.grupowapps.es/client/vapidPublicKey`);
      const data = await response.json();
      const publicKey = data.vpk;
      const permission = await Notification.requestPermission();

      if (permission !== 'granted') {
        console.error('Permiso para notificaciones no concedido');
        return;
      }
      console.log(publicKey)
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(publicKey) // Clave pública
      });
      await fetch(`https://omioback.grupowapps.es/client/suscribepush`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subscription })
      });
      this.suscribed = true;
    },

    urlBase64ToUint8Array(base64String) {
      console.log(base64String)
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    formatTime(date) {
      // Obtener horas y minutos
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      // Concatenar horas y minutos con ":"
      return `${hours}:${minutes}`;
    },
    changeStatus() {
      let url = this.base_url + '/updaterestaurantstatus';
      this.axios.post(url, { status: !this.isOpen }, {
        headers: {
          'Authorization': this.$session.get('token')
        }
      }).then(() => {
        this.getStatus()
      })
    },

    getStatus() {
      if (this.$session.exists()) {
        let url = this.base_url + '/getrestaurantstatus';
        this.axios.get(url, {
          headers: {
            'Authorization': this.$session.get('token')
          }
        }).then(response => {
          this.isOpen = response.data.status;
          this.selected = response.data.view;
          this.$session.set('status', response.data.status);
          this.$session.set('view', response.data.view);
        });
      }
    },
    logout() {
      this.$session.destroy();
      this.$router.push('/login');
      location.reload();
    }
  }
}
</script>
<style>
body {
  background: black;
}

.iconView {
  position: fixed !important;
  right: 10vw;
  top: 3vh
}

.statusIcon {
  position: fixed !important;
  right: 4vw;
  top: 2vh
}
.v-list-item__content{
  margin-left:-15px
}
</style>
