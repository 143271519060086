<template>
  <li @mousedown="startPress" @mouseup="endPress" @mouseleave="cancelPress" @contextmenu.prevent="longClick"
    v-bind:class="{ 'current': order.status == 3, 'cooked': order.status == 4, 'fornet': order.status == 5, 'orderNote': 1 == 1, 'deliveryNote': order.typus == 2, 'takeawayNote': order.typus == 1, 'restaurantNote': order.typus == 3 }">
    <h3 class="statusHead">
      {{ getOrderStatus() }}
      <v-icon v-if="order.status == 0" icon="mdi-archive-outline"> </v-icon>
      <v-icon v-if="order.status == 1" icon="mdi-stove"></v-icon>
      <v-icon v-if="order.status == 2" icon="mdi-clock-outline"></v-icon>
      <v-icon v-if="order.status == 3" icon="mdi-chef-hat"></v-icon>
      <v-icon v-if="order.status == 4" icon="mdi-silverware-fork-knife"></v-icon>
      <v-icon v-if="order.status == 5" icon="mdi-bag-personal-outline"></v-icon>
      <br v-if="order.typus == 2 && order.isFast == false"> <span v-if="order.typus == 2 && order.isFast == false">Pedido para las {{ order.hour }}</span>
    </h3>

    <div class="head">
      <h3 style="display: flex"> #{{ order.number }} </h3>
      <v-divider vertical class="ml-1 mr-1"></v-divider>
      <h3 style="text-align: center;" v-if="order.isFast">{{ order.name }} </h3>
      <h3 style="text-align: center;" v-else>{{ order.name }} - {{ order.phone }}</h3>

    </div>

    <div>
      <div v-for="prod in order.itemsnow" :key="prod.id">
        <p class="productTitle" style="font-weight: bold" :class="{ 'valid': prod.valid, 'toggled': prod.completed }">
          {{ prod.quantity }} x {{ prod.product }}</p>
          <p class="complement mt-1" v-for="complement in prod.complements" :key="complement.name"
          :class="{ 'valid': prod.valid, 'toggled': prod.completed }">  {{complement.name.includes('SENSE') ? '- ':complement.name.includes('AMB') ? '+ ': '   '}} {{ complement.name}}

        </p>
      </div>
    </div>
    <hr class="separador" v-if="order.itemslater.length > 0">
    <div v-if="order.itemslater.length > 0">
      <div v-for="prod in order.itemslater" :key="prod.id">
        <p class="productTitle" style="font-weight: bold" :class="{ 'valid': prod.valid, 'toggled': prod.completed }">
          {{ prod.quantity }} x {{ prod.product }} </p>
        <p class="complement mt-1" v-for="complement in prod.complements" :key="complement.name"
          :class="{ 'valid': prod.valid, 'toggled': prod.completed }">  {{complement.name.includes('SENSE') ? '- ':complement.name.includes('AMB') ? '+ ': '   '}} {{ complement.name}}

        </p>
      </div>
    </div>
    <h3 style="text-align: center;font-size:16pt" v-if="order.typus != 1">{{ (order.amount) }}€ - {{ getOrderPayment()
      }}<v-icon v-if="!order.isPaid" icon="mdi-alert-outline" color="orange"></v-icon></h3>
    <h3 style="color: gray; text-align: center;font-size: 10pt;">{{ getFormattedDate(order.date) }}</h3>
    <v-btn block :loading="loading" style="text-align:center;" variant="outlined" v-if="!order.isPaid"
      v-on:click="payorder(order.oid)">Cobrar</v-btn>
    <v-btn block style="text-align:center; " variant="outlined" v-if="order.typus != 1 && order.isPaid && order.status != 6"
      v-on:click="finishOrder(order.oid)">Entregar
      pedido</v-btn>
    <v-btn block style="text-align:center; " variant="outlined" v-if="order.typus == 1 && order.isPaid && order.status != 6"
      v-on:click="liberarmesa(order.oid)">Liberar mesa</v-btn>

  </li>
</template>

<script>
export default {
  name: "orderNote",
  props: ['order', 'updateview','loading','payorder'],
  data: () => ({
    intervalo: null,
    orderStatus: '',
    paymentTypus: 'pagado'
  }),
  methods: {
    rightClick(){

    },
    startPress() {
      this.isLongClick = false;

      this.pressTimer = setTimeout(() => {
        this.isLongClick = true;
        this.longClick();
      }, 500);
    },
    endPress() {
      if (!this.isLongClick) {
        this.click();
      }
      clearTimeout(this.pressTimer);
    },
    cancelPress() {
      clearTimeout(this.pressTimer);
    },
    click() {
      console.log("Click normal");

    },
    longClick() {
      this.$router.push('/pedido/' + this.order.oid)
    },
    getFormattedDate(fechaISO) {
      const fecha = new Date(fechaISO);

      // Obtener componentes de la fecha
      const dia = fecha.getUTCDate().toString().padStart(2, '0'); // Día (2 dígitos)
      const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0'); // Mes (sumar 1 ya que los meses en JS empiezan en 0)
      const anio = fecha.getUTCFullYear(); // Año

      // Obtener componentes de la hora
      const horas = fecha.getUTCHours().toString().padStart(2, '0'); // Horas (formato 24h)
      const minutos = fecha.getUTCMinutes().toString().padStart(2, '0'); // Minutos
      const segundos = fecha.getUTCSeconds().toString().padStart(2, '0'); // Segundos

      // Formatear la fecha y hora en el formato deseado
      const fechaFormateada = `${dia}/${mes}/${anio} - ${horas}:${minutos}:${segundos}`;

      return fechaFormateada
    },
    liberarmesa(orderid) {
      this.axios.post(`${this.$apiUrl}/client/finishorder`, { oid: orderid }).then(() => {
        this.updateview()
      })
    },
    finishOrder(orderid) {
      this.axios.post(`${this.$apiUrl}/client/finishorder`, { oid: orderid }).then(() => {
        this.updateview()
      }).catch(err => {
        console.log(err)
      })
    },
   
    
    getOrderStatus() {
      switch (this.order.status) {
        case 2:
          return 'En cola'
        case 3:
          return 'Preparando'
        case 4:
          return 'Cocinado'
        case 5:
        return this.order.typus == 2?
           'En fornet' : 'Mesa completada'
        case 6:
          return 'Entregado'
      }
    },
    getOrderPayment(){
      return this.order.isPaid ? 'Pagado' : 'Sin pagar'
    }
  },


}
</script>
<style>
.statusHead {
  text-align: center;
  border-bottom: 1px solid lightgray;
  padding: 0 0 5px 0px;
  margin-bottom: 5px;
}

li {

  border: 2px solid transparent;
  cursor: pointer;
}


li.current {

  border: 5px solid orange;

}


li.cooked {

  border: 5px solid #7cf27c;

}

li.fornet {

  border: 5px solid red;

}

.orderNote {
  color: black
}

.toggled {
  text-decoration: line-through;
}

.head {
  display: flex;
  flex-direction: row;
}

.productTitle {
  font-weight: bold;
  font-size: 16pt;
  margin-bottom: 0vh !important;
}

.complement {
  margin: -1vh 0 1vh 1vw !important;
  font-size: 14pt;
}

.separador {
  border: none;
  border-top: 2px dashed black;
  /* Línea punteada */
  height: 2px;
  width: 100%;
  /* Ocupa el 100% del ancho del contenedor */
  margin: 20px 0;
  /* Márgenes opcionales para separación vertical */
}
</style>
